import { Link } from 'react-router-dom'
import { Container, Heading, Box, Text, List, ListItem, Image, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import Layout from '../../components/layouts/article'
import { Meta } from '../../components/project'

import imageOne from '../../images/em-1.png'
import imageTwo from '../../images/em-2.png'

const Energyminute = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { isOpen: isOpenTwo, onOpen: onOpenTwo, onClose: onCloseTwo } = useDisclosure()

    return (
        <Layout>
            <Container>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.1 }} mb={6}>
                    <Box align="left" my={8}>
                        <Text fontSize="sm">
                            <Link style={{ textDecoration: 'underline' }} to={'/'}>
                                Home
                            </Link>{' '}
                            &gt; Project &gt; ENERGYminute{' '}
                        </Text>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.3 }} mb={6}>
                    <Heading as="h3" size="lg">
                        ENERGYminute
                    </Heading>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.5 }} mb={6}>
                    <Box align="left" my={12}>
                        <p>Redesign and development of website for news and media platform.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.7 }} mb={6}>
                    <List ml={4} my={4}>
                        <ListItem>
                            <Meta>Website</Meta>
                            <a target="_blank" href="https://energyminute.ca/">
                                https://energyminute.ca/ <ExternalLinkIcon mx="2px" />
                            </a>
                        </ListItem>
                        <ListItem>
                            <Meta>Technology Stack</Meta>
                            <span>Wordpress, PHP, Javascript, ChartJS, Advanced Custom Fields, Adobe Creative Suite</span>
                        </ListItem>
                    </List>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.9 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpen} src={imageOne} alt="Login" />
                        </Box>
                    </motion.div>
                    <Box align="left" my={0}>
                        <p>Frontpage with prominent hero banner with Call To Action for email subscription signups.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 0.9 }} mb={6}>
                    <motion.div
                        whileHover={{
                            scale: 1.01,
                        }}
                        transition={{ type: 'spring', stiffness: 400 }}
                    >
                        <Box
                            _hover={{
                                boxShadow: '0 3px 10px 0px rgba(0,0,0,0.25)',
                                cursor: 'pointer',
                            }}
                            align="left"
                            my={12}
                        >
                            <Image onClick={onOpenTwo} src={imageTwo} alt="Wireframe concept" />
                        </Box>
                    </motion.div>
                    <Box align="left" mt={12} mb={6}></Box>
                    <Box align="left" my={0}>
                        <p>Interactive map module with custom charts.</p>
                    </Box>
                </motion.div>
                <motion.div initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.8, delay: 1.3 }} mb={6}>
                    <Box align="left" my={12}>
                        <span>Visit </span>
                        <a style={{ fontWeight: '700' }} href="https://energyminute.ca/" target="_blank">
                            ENERGYminute
                        </a>
                        .
                    </Box>
                </motion.div>
            </Container>
            <Modal size={'5xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageOne} alt="em large one" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
            <Modal size={'5xl'} isOpen={isOpenTwo} onClose={onCloseTwo}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Image boxSize="100%" src={imageTwo} alt="em large two" />
                    </ModalBody>
                    <ModalFooter></ModalFooter>
                </ModalContent>
            </Modal>
        </Layout>
    )
}

export default Energyminute
