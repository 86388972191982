import { useEffect, useState } from 'react'
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import { ChakraProvider, extendTheme, Container, Box, Spinner, Text } from '@chakra-ui/react'
import Layout from './components/layouts/main'
import { AnimatePresence } from 'framer-motion'
import { motion } from 'framer-motion'

import '@fontsource/m-plus-rounded-1c'
import EnergyMinute from './pages/projects/Energyminute'
import SeamShift from './pages/projects/SeamShift'
import TheGapAndTheGain from './pages/projects/TheGapAndTheGain'
import LALALAI from './pages/projects/LALALAI'
import ESPN from './pages/projects/ESPN'
import Home from './pages/home'
import FourOhFour from './pages/404'
import ScrollToTop from './components/scrollToTop'
import bg from './images/bg2-e.jpg'

const theme = extendTheme({
    fonts: {
        heading: "'M PLUS Rounded 1c', sans-serif",
    },
    styles: {
        global: {
            body: {
                bg: '',
                color: 'gray.700',
            },
            a: {
                color: 'white.',
                _hover: {
                    textDecoration: 'underline',
                },
            },
        },
    },
    breakpoints: {
        sm: '500px',
        md: '768px',
        lg: '960px',
        xl: '1200px',
    },
})

function App() {
    const [src, setSrc] = useState('')
    const [isLoaded, setLoaded] = useState(false)

    useEffect(() => {
        loadBackground()
    }, [])

    async function loadBackground() {
        const imageToLoad = new Image()
        imageToLoad.src = bg
        imageToLoad.onload = async () => {
            setSrc(bg)
            await new Promise((resolve) => setTimeout(resolve, 1000))
            setLoaded(true)
        }
    }

    return (
        <ChakraProvider theme={theme}>
            <div className="app">
                <AnimatePresence exitBeforeEnter initial={true}>
                    {isLoaded ? (
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            <img src={src} className="bg-static" />
                            <Layout>
                                <BrowserRouter>
                                    <ScrollToTop />
                                    <Routes>
                                        <Route path="/" exact element={<Home />} />
                                        <Route path="/project/SeamShift" element={<SeamShift />} />
                                        <Route path="/project/EnergyMinute" element={<EnergyMinute />} />
                                        <Route path="/project/TheGapAndTheGain" element={<TheGapAndTheGain />} />
                                        <Route path="/project/LALALAI" element={<LALALAI />} />
                                        <Route path="/project/espn" element={<ESPN />} />
                                        <Route path="*" element={<FourOhFour />} />
                                    </Routes>
                                </BrowserRouter>
                            </Layout>
                        </motion.div>
                    ) : (
                        <Layout>
                            <Container>
                                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.8 }} mb={6}>
                                    <Box align="center" my={8}>
                                        <Spinner size="xl" />
                                        <Text mt={4}>Loading...</Text>
                                    </Box>
                                </motion.div>
                            </Container>
                        </Layout>
                    )}
                </AnimatePresence>
            </div>
        </ChakraProvider>
    )
}

export default App
